import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import "./src/styles/global.scss";


import React from "react";
import {GatsbyBrowser} from "gatsby";
import {RootProvider} from "./src/contexts/root.context";

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => <RootProvider>{element}</RootProvider>;
